import React from 'react';
import { FaFilePdf } from 'react-icons/fa';

const Volume1Issue1 = () => {
  const articles = [
    {
      number: 1,
      title: 'Morphometric classification using multivariate methods and remote data of Morocco\'s steep coasts',
      author: 'Ismail Farhaoui & Mohamed Dakki & Abbelmalek Saloui & Othmane Farhaoui',
      pages: '01-14',
      link: 'https://ijngtsr-paper.live/paper/2024/sep/ijngtsr_01__01.html',
    },
  ];

  return (
    <div className="min-h-screen bg-gray-800 p-2">
      <div className="max-w-6xl mx-0 sm:mx-auto bg-gray-800 shadow-lg rounded-lg">
        <h1 className="text-2xl font-bold text-white mb-6 text-center animate-pulse">Volume 1 - Issue 1</h1>
        {/* Table header for larger screens */}
        <div className="hidden md:grid grid-cols-12 gap-4 p-4 bg-gray-800 text-gray-200 font-bold text-center rounded-lg border-b border-[#f0ec0c]">
          <div className="col-span-1">S.No.</div>
          <div className="col-span-6">Title & Author Name</div>
          <div className="col-span-2">Pages</div>
          <div className="col-span-3">View PDF</div>
        </div>
        {articles.map((article, index) => (
          <div
            key={index}
            className="grid grid-cols-1 md:grid-cols-12 mt-4 gap-4 p-4 border-b border-yellow-300 bg-gray-700 hover:bg-gray-600 items-center rounded-lg transform hover:scale-105 transition-transform duration-300"
          >
            {/* Mobile */}
            <div className="block md:hidden text-white">
              <p className="font-bold text-gray-50 text-center mb-2 border-b border-gray-500">Article : {article.number}</p>
              <h2 className=" text-gray-50">Article Title : <br />{article.title}</h2>
              <p className="text-sm text-yellow-200 mt-2">Author : <br />{article.author}</p>
              <div className="flex justify-between items-center mt-4">
                <div className="text-gray-100 text-xs">
                  Pages : {article.pages}
                </div>
                <div>
                  <a
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-50 flex items-center hover:text-blue-200 animate-bounce text-sm"
                  >
                    <FaFilePdf className="mr-2" /> View PDF
                  </a>
                </div>
              </div>
            </div>
            {/* Desktop */}
            <div className="hidden md:block col-span-1 text-white font-bold text-center md:text-left">
              {article.number}
            </div>
            <div className="hidden md:block col-span-6 text-left">
              <h2 className="font-semibold text-gray-50">{article.title}</h2>
              <p className="text-yellow-200 text-sm mt-1">{article.author}</p>
            </div>
            <div className="hidden md:block col-span-2 text-gray-100 text-center md:text-center">
              {article.pages}
            </div>
            <div className="hidden md:block col-span-3 text-right md:text-right ml-3">
              <a
                href={article.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-50 flex items-center justify-center md:justify-start hover:text-blue-200 animate-bounce"
              >
                <FaFilePdf className="mr-2" /> View PDF
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Volume1Issue1;
