import React, { useState } from 'react';

const ContactForHelp = () => {
  const [formData, setFormData] = useState({
    authorName: '',
    email: '',
    country: '',
    message: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(async () => {
      try {
        const response = await fetch('https://gvlzzsnpc6.execute-api.ap-south-1.amazonaws.com', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.authorName,
            email: formData.email,
            country: formData.country,
            message: formData.message,
          }),
        });

        const data = await response.json();
        setResponseMessage(data.message);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);

        setFormData({
          authorName: '',
          email: '',
          country: '',
          message: '',
        });
      } catch (error) {
        console.error('Error:', error);
        setResponseMessage('Failed to send message. Please try again later.');
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 5000);
      } finally {
        setLoading(false);
      }
    }, 2000);
  };

  return (
    <div className="p-6 bg-gray-700 rounded-lg mx-auto">
      <h1 className="text-2xl font-bold mb-4 animate-pulse">Need help?</h1>
      <p className="text-lg text-gray-300 mb-4">Contact with Experts of IJNGTSR: International Journal of Next Generation Technology and Science Research</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-100 text-sm font-bold mb-2" htmlFor="authorName">
            Author Name:
          </label>
          <input
            type="text"
            id="authorName"
            name="authorName"
            value={formData.authorName}
            onChange={handleChange}
            className="shadow appearance-none bg-[#e8f0fe] border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-100 text-sm font-bold mb-2" htmlFor="email">
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="shadow appearance-none border bg-[#e8f0fe] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-100 text-sm font-bold mb-2" htmlFor="country">
            Country:
          </label>
          <input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            className="shadow appearance-none border bg-[#e8f0fe]  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-100 text-sm font-bold mb-2" htmlFor="message">
            Message:
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="shadow appearance-none border bg-[#e8f0fe] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="4"
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={loading}
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8V12H4z"
                ></path>
              </svg>
            ) : (
              'SUBMIT'
            )}
          </button>
        </div>
        {showMessage && (
        <div className="mb-4 p-4 bg-green-100 text-green-700 rounded mt-5">
          {responseMessage}
        </div>
      )}
      </form>
    </div>
  );
};

export default ContactForHelp;
