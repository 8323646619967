import React from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

function Contact() {
  return (
    <div>
      <Helmet>
        <meta charset="utf-8" />
        <title>IJNGTSR - Contact-Us</title>
        <meta name="description" content="We are publishing free of cost. IJNGTSR. Home · Track Article New · Aim and scope. Editorial Committee. Latest Announcement ... IJNGTSR All Rights Reserved. International Journal of Next Generation Technology and Science Research (IJNGTSR) - A leading journal dedicated to publishing cutting-edge research in technology and science. Explore research papers, impact factors, and more." />
        <meta name="keywords" content="International Journal, Next Generation Technology, Science Research, Technology Journal, Science Journal, Paper Publication, Research Papers, Impact Factor, ISSN Number, Online Paper Publication, Technology Research, Science Research, IJNGTSR" />
      </Helmet>
      <div className="bg-gray-800 text-white max-w-4xl mx-auto px-4 py-6">
        <header className="text-center mb-8">
          <h1 className="text-3xl font-bold mb-2 animate-pulse">
            <FaMapMarkerAlt className="inline-block mr-2" />Contact Us
          </h1>
        </header>

        <section className="bg-gray-700 p-6 rounded-lg shadow-lg mb-8 transform hover:scale-105 transition-transform duration-300 ease-in-out">
          <h2 className="text-2xl font-semibold mb-4 animate-bounce">
            <FaMapMarkerAlt className="inline-block mr-2" /> Address
          </h2>
          <p className="text-base mb-4">
            03, Old Kota Fatak<br />
            Near at Ram Mandir, Nagda (456335)<br />
            Madhya Pradesh, India
          </p>
        </section>

        <section className="bg-gray-700 p-6 rounded-lg shadow-lg mb-8 transform hover:scale-105 transition-transform duration-300 ease-in-out">
          <h2 className="text-2xl font-semibold mb-4 animate-bounce">
            <FaEnvelope className="inline-block mr-2" /> Email
          </h2>
          <p className="text-base mb-4">
            <a href="mailto:submit@ijngtsr.org" className="text-blue-400 hover:underline flex items-center">
              <FaEnvelope className="mr-2" /> submit@ijngtsr.org
            </a>
          </p>
        </section>

        <section className="bg-gray-700 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
          <h2 className="text-2xl font-semibold mb-4 animate-bounce">
            <FaPhone className="inline-block mr-2" /> Phone
          </h2>
          <p className="text-base">
            <a href="tel:+917880059117" className="text-blue-400 hover:underline flex items-center">
              <FaPhone className="mr-2" /> +91-877-005-9117
            </a>
          </p>
        </section>
      </div>
    </div>
  );
}

export default Contact;