import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaTrash, FaEye } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import RefreshButton from './Refresh';

const Showmember = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deletingArticle, setDeletingArticle] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [currentPage] = useState(1);
    const [articlesPerPage] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            navigate('/admin');
        } else {
            fetchArticles();
        }
    }, [navigate]);

    const API_KEY = '154122054';

    const fetchArticles = async () => {

        try {
            const response = await fetch('https://y7v8shlb4c.execute-api.ap-south-1.amazonaws.com/default/EditorialBoardMembers-lambda', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': API_KEY,
                },
            });

            const data = await response.json();

            if (Array.isArray(data)) {
                setArticles(data);
            } else {
                console.error('...');
            }
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    const handleLogout = () => {
        setLoading(true);
        setTimeout(() => {
            localStorage.removeItem('authToken');
            navigate('/admin');
        }, 2000);
    };

    const handleDelete = async () => {
        try {
            await fetch('https://y7v8shlb4c.execute-api.ap-south-1.amazonaws.com/default/EditorialBoardMembers-lambda', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': API_KEY,
                },
                body: JSON.stringify({
                    action: 'delete',
                    id: deletingArticle.id,
                }),
            });
            setSuccessMessage(' Deleted Successfully');
            setShowSuccess(true);
            setShowDeleteConfirm(false);
            fetchArticles();
            setTimeout(() => {
                setShowSuccess(false);
            }, 3000);
        } catch (error) {
            console.error('Error deleting :', error);
        }
    };

    const handleShowDetails = (article) => {
        setSelectedArticle(article);
        setShowDetails(true);
    };


    // Pagination Logic
    const indexOfLastArticle = currentPage * articlesPerPage;
    const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
    const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);



    return (
        <>
            <NavLink to={'/dashb'}>
                <div className="flex items-center space-x-2 p-2 bg-gray-800 rounded-lg transition duration-300">
                    <FaArrowLeft className="text-2xl text-white" />
                    <h4 className="text-lg font-semibold text-white">Back</h4>
                </div>
            </NavLink>
            <div className="min-h-screen flex flex-col items-center bg-gray-800 text-gray-100 p-4 mt-4">
                <div className="w-full flex justify-between items-center mb-6">
                    <h1 className="text-[20px] sm:text-4xl font-bold">Show Member</h1>
                    <RefreshButton onRefresh={fetchArticles} />
                    <button
                        onClick={handleLogout}
                        className="relative bg-red-600 text-white font-bold py-1 px-2 mt-0 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 transition duration-300"
                        disabled={loading}
                    >
                        {loading ? (
                            <svg
                                className="animate-spin h-5 w-5 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.964 7.964 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        ) : (
                            'Logout'
                        )}
                    </button>
                </div>

                {currentArticles.length === 0 ? (
                    <div className="p-4 rounded-lg text-center">
                        <p className="text-red-600 text-lg font-semibold">No Member Available.</p>
                    </div>
                ) : (
                    <div className="overflow-x-auto w-full">
                        <table className="min-w-full bg-gray-700 rounded-lg overflow-hidden text-center">
                            <thead>
                                <tr>
                                    <th className="py-2 px-4 border-b border-gray-600">ID</th>
                                    <th className="py-2 px-4 border-b border-gray-600">Author Name</th>
                                    <th className="py-2 px-4 border-b border-gray-600">Date</th>
                                    <th className="py-2 px-4 border-b border-gray-600">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentArticles.map((article, index) => (
                                    <tr key={article.id}>
                                        <td className="py-2 px-4 border-b border-gray-600">{indexOfFirstArticle + index + 1}</td>
                                        <td className="py-2 px-4 border-b border-gray-600 whitespace-nowrap text-left">
                                            {`${article.fullName || 'N/A'}`}
                                        </td>
                                        <td className="py-2 px-4 border-b border-gray-600 whitespace-nowrap">
                                            {new Date(article.createdAt).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: 'long',
                                                year: 'numeric',
                                            })}
                                        </td>
                                        <td className="py-2 px-4 border-b border-gray-600 flex items-center justify-center space-x-2">
                                            <button
                                                onClick={() => handleShowDetails(article)}
                                                className="text-blue-500 hover:text-blue-700 flex items-center mr-1"
                                            >
                                                <FaEye className="text-lg min-w-6 min-h-6" />
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setDeletingArticle(article);
                                                    setShowDeleteConfirm(true);
                                                }}
                                                className="text-red-500 hover:text-red-700 flex items-center ml-1"
                                            >
                                                <FaTrash className="text-lg min-w-4 min-h-4" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {showDeleteConfirm && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75">
                        <div className="bg-gray-700 p-4 rounded-lg shadow-lg lg:w-1/3">
                            <h2 className="text-xl font-bold mb-4">Delete Article</h2>
                            <p className="mb-4">Are you sure you want to delete this article?</p>
                            <div className="flex justify-end space-x-4">
                                <button
                                    onClick={handleDelete}
                                    className="bg-red-700 text-white font-bold py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-600 transition duration-300"
                                >
                                    Delete
                                </button>
                                <button
                                    onClick={() => setShowDeleteConfirm(false)}
                                    className="bg-gray-600 text-white font-bold py-2 px-4 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 transition duration-300"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showDetails && selectedArticle && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75 overflow-auto">
                        <div className="bg-gray-700 p-8 rounded-lg shadow-lg w-full max-w-4xl max-h-screen mx-auto overflow-y-auto">
                            <h2 className="text-2xl font-bold mb-4 text-center">Member Details</h2>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                <p className="mb-4">Date: {new Date(selectedArticle.createdAt).toLocaleDateString('en-GB', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                })}</p>
                                <p className="mb-4">Time: {selectedArticle.createdAt ? new Date(selectedArticle.createdAt).toLocaleTimeString('en-US', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                }) : 'N/A'}</p>
                                {/* <p className="mb-4">Salutation: <strong>{selectedArticle.salutation || 'N/A'}</strong></p> */}
                                <p className="mb-4">Name: {selectedArticle.salutation || 'N/A'}{selectedArticle.fullName || 'N/A'}</p>
                                <p className="mb-4">Research Experience: {selectedArticle.researchExperience || 'N/A'}</p>
                                <p className="mb-4">Affiliation: {selectedArticle.affiliation || 'N/A'}</p>
                                <p className="mb-4">Designation: {selectedArticle.designation || 'N/A'}</p>
                                <p className="mb-4">Academic Experience: {selectedArticle.academicExperience || 'N/A'}</p>
                                <p className="mb-4">Country: {selectedArticle.country || 'N/A'}</p>
                                </div>
                                <p className="mb-4 mt-4">Email: {selectedArticle.email || 'N/A'}</p>
                            <div className="flex justify-end space-x-4">
                                <button
                                    onClick={() => setShowDetails(false)}
                                    className="bg-gray-600 text-white font-bold py-2 px-4 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 transition duration-300"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>

                )}

                {showSuccess && (
                    <div className="mt-4 p-4 bg-green-500 text-white rounded-lg">
                        {successMessage}
                    </div>
                )}
            </div>
        </>
    );
};

export default Showmember;
